import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "frame", "consentForm", "saveCheckbox" ]
  static values = { src: String, saveCheckboxPath: String }

  connect() {
    console.log("hello")
    console.log(this.frameTarget)
    console.log(this.consentFormTarget)
  }

  consent(e) {
    this.frameTarget.src = this.srcValue
    this.frameTarget.classList.remove("invisible")
    this.consentFormTarget.classList.add("invisible")

    if (this.saveCheckboxTarget.checked) {
      fetch(this.saveCheckboxPathValue, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // redirect: "follow", // manual, *follow, error
        // body: {JSON.stringify(data)}, // body data type must match "Content-Type" header
      });
    }

  }

}
