import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "factor", "result" ]
  static values = { factor: Number }

  change(e) {
    console.log('c')
    this.factorValue = this.factorTarget.value

    e.preventDefault()
    e.stopPropagation()
  }

  factorValueChanged() {
    console.log('fvc')
    let factor = this.factorValue
    let result = 0

    if (factor > 0 && factor <= 10) {
      result = `${factor * 449} €`
    } else if (factor > 10 && factor <= 20) {
      result = `${factor * 404} €`
    } else if (factor > 20) {
      result = "Auf Anfrage"
    } else {
      result = "-"
    }

    this.resultTarget.innerText = result
  }


}
