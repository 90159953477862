import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "toggler", "togglee", "toggleeIndex" ]

  toggle(e) {
    let index = this.togglerTargets.indexOf(e.currentTarget);

    if (this.haveToggleeTargets) {
      this.toggleeTargets[index].classList.toggle("visible");
    }
    
    this.togglerTargets[index].classList.toggle("target-visible");

    if (this.togglerTargets[index].dataset.hideAfterToggle) {
      this.togglerTargets[index].style.display = 'none';
    }

    this.toggleeIndexTargets.forEach((el, i) => {
      if (el.dataset.toggleeIndex == index){
        el.classList.toggle('visible')
      } else {
        el.classList.remove('visible')
      }
    })

  }

  toggleCloseOther(e) {
    let index = this.togglerTargets.indexOf(e.currentTarget)

    this.toggleeTargets.forEach((el, i) => {
      if (i == index) {
        el.classList.toggle("visible")
      } else {
        el.classList.remove('visible')
      }
    })

    this.toggleeIndexTargets.forEach((el, i) => {
      if (el.dataset.toggleeIndex == index){
        el.classList.toggle('visible')
      } else {
        el.classList.remove('visible')
      }
    })


    this.togglerTargets.forEach((el, i) => {
      if (i == index) {
        el.classList.toggle("target-visible")
        el.setAttribute("aria-expanded", true)
      } else {
        el.classList.remove('target-visible')
        el.setAttribute("aria-expanded", false)
      }
    })

  }

}